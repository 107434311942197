import { Image } from "@nextui-org/react";
import Head from "next/head";

export default function NotFound() {
  return (
    <>
      <Head>
        <title>Page Not Found</title>
        <meta property="og:title" content="Page Not Found" key="title" />
        <meta
          name="description"
          content="The page you are looking for could not be found. Please check the URL or return to the homepage."
        />
        <meta
          property="og:description"
          content="The page you are looking for could not be found. Please check the URL or return to the homepage."
        />
      </Head>
      <div
        className="container h-screen flex justify-center py-36"
        style={{
          backgroundImage: "url(/404.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-7xl w-full flex">
          <div className="flex flex-col items-center">
            <Image
              src="/404-header.webp"
              alt="404 Not Found"
              radius="none"
              className="mb-4"
            />
            <p className="max-w-[480px] w-full text-center">
              Pardon us, {"we're"} having some trouble finding this page. It may
              be because:
            </p>
            <ul className="list-none font-bold my-6">
              <li>The link or bookmark that took you here is outdated</li>
              <li> The page has moved to a different address </li>
              <li>The web address is typed incorrectly </li>
            </ul>
            <p className="max-w-[480px] w-full text-center">
              Try checking the URL, and if that looks okay then use the search
              bar or links at the top of the page to find what {"you're"}{" "}
              looking for.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
